import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function RadioButton(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { label, name, className, checked, onChange, ...radioProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [isInvalid, setIsInvalid] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleInvalidStatus(event) {
    event.preventDefault()

    setIsInvalid(true)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <label className={`${className}${isInvalid ? " invalid" : ""}`}>
      <div className="label-container">{label}</div>

      <input
        type="radio"
        name={name}
        {...radioProps}
        checked={checked}
        onClick={onChange}
        onInvalid={handleInvalidStatus}
      />

      <span className="checkmark" />
    </label>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

RadioButton.propTypes = {
  className: PropTypes.string.isRequired,
  mode: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  checked: PropTypes.any,
  onChange: PropTypes.func,
}

RadioButton.defaultProps = {
  mode: "horizontal",
  name: "radio",
  onChange: () => {},
  checked: false,
}

// ----------------------------------------------------------------------------

const StyledRadioButton = styled(RadioButton)`
  & {
    /* The container */
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-left: 26px;
    min-height: 16px;
    height: auto;
    max-width: 134px;
    cursor: pointer;
    font-family: arial;
    font-size: 14px;
    line-height: 16px;
    user-select: none;

    .label-container {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      background-color: ${props => props.theme.white};
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    :hover input ~ .checkmark {
      ${"" /* background-color: ${props => props.theme.white}; */}
      background-color: #f5f5f5;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: ${props => props.theme.white};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${props => props.theme.charlestonGrey};
    }
  }

  &.invalid {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledRadioButton
