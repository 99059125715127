import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import FloatingButton from "../../../FloatingButton"

//------------------------------------------------------------------------------

function SearchTextInput(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { theme, onSearch } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [inputValue, setInputValue] = useState(null)
  const [isInputVisible, setIsInputVisible] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function showTextInput() {
    setIsInputVisible(true)
  }

  function handleSearch() {
    onSearch && onSearch(inputValue)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <div
        className={`search-icn${isInputVisible ? " input-visible" : ""}`}
        src={require("../../../../../images/_shared/icons/search-icn.svg")}
        onClick={showTextInput}
      />
      <input
        className={`search-input${isInputVisible ? " visible" : ""}`}
        placeholder="Qualche dubbio?"
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
      />

      <div className={`search-btn${isInputVisible ? " input-visible" : ""}`}>
        <FloatingButton
          icon={require("../../../../../images/_shared/icons/right-arrow-icn.svg")}
          hoverIcon={require("../../../../../images/_shared/icons/right-arrow-white-icn.svg")}
          backgroundColor={theme.isabelline}
          hoverBackgroundColor={theme.yaleBlue}
          onClick={handleSearch}
        />
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

SearchTextInput.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired, // styled-components theme
  onSearch: PropTypes.func,
}

SearchTextInput.defaultProps = {
  onSearch: () => {},
}

// ----------------------------------------------------------------------------

const SearchTextInputWithTheme = withTheme(SearchTextInput)

// ----------------------------------------------------------------------------

const StyledSearchTextInput = styled(SearchTextInputWithTheme)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 100%;

    .search-icn {
      height: 16px;
      width: 16px;
      background-image: url(${require("../../../../../images/_shared/icons/search-icn.svg")});
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-origin: content-box;

      cursor: pointer;

      transition: all 0.2s ease;

      :hover {
        background-image: url(${require("../../../../../images/_shared/icons/search-pearl-aqua-icn.svg")});
      }
    }

    .search-icn.input-visible {
      height: 0;
      width: 0;
      opacity: 0;
      transform: translateX(-100px);
      transition: all 0.2s ease-in-out;
    }

    .search-input {
      box-sizing: border-box;
      max-width: 0;
      width: 200px;

      border: none;
      background-color: transparent;

      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: ${props => props.theme.primaryTextColor};
      transition: max-width 0.3s ease-in-out;

      ::placeholder {
        font-weight: normal;
      }

      :focus {
        outline: none;
      }
    }

    .search-input.visible {
      max-width: 200px;
    }

    .search-btn {
      width: 0;
      height: 0;
      opacity: 0;
      transform: translateX(100px);
      transition: all 0.2s ease-in-out 0.3s;
    }

    .search-btn.input-visible {
      width: auto;
      height: auto;
      opacity: 1;
      transform: translateX(0);
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledSearchTextInput
