import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

//------------------------------------------------------------------------------

function TextInput(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, ...inputProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const inputElement = useRef(null)
  const [isInvalid, setIsInvalid] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleInvalidStatus(event) {
    event.preventDefault()

    setIsInvalid(true)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <input
      ref={inputElement}
      className={`${className}${isInvalid ? " invalid" : ""}`}
      {...inputProps}
      onInvalid={handleInvalidStatus}
    />
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TextInput.propTypes = {
  className: PropTypes.string.isRequired,
}

TextInput.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTextInput = styled(TextInput)`
  & {
    box-sizing: border-box;
    padding: 0 16px;
    background-color: ${props => props.theme.white};
    border-style: none;
    border-radius: 2px;
    height: 50px;
    width: 100%;

    line-height: 50px;
    font-size: 16px;
    color: ${props => props.theme.primaryTextColor};
    font-weight: 600;

    ::placeholder {
      color: ${props => props.theme.primaryTextColor};
      font-weight: normal;
    }

    :focus {
      outline: none;
      background-color: ${props => props.theme.jonquil};
      font-weight: 600;
    }
  }

  &.invalid {
    border: 3px solid ${props => props.theme.jonquil};
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledTextInput
