import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import RoundedButton from "../../RoundedButton"

import CreditsModal from "../CreditsModal"

//------------------------------------------------------------------------------

function Footer(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [isCreditsModalVisible, setIsCreditsModalVisible] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function goToGlookeWebsite() {
    const glookeUrl = "https://glooke.com"

    typeof window !== "undefined" && window.open(glookeUrl, glookeUrl)
  }

  function handleToggleCreditsModal() {
    const newModalVisibility = !isCreditsModalVisible

    setIsCreditsModalVisible(newModalVisibility)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <footer className={props.className}>
      <div className="info-container">
        <img
          className="logo"
          src={require("../../../../images/logo/favicon.png")}
          width={47.72}
          height={23.1}
        />

        <div className="column first-column">
          <p className="company-name">Salesbridge S.r.l.</p>

          <dl>
            <dt>Indirizzo</dt>
            <dd>Via per Salvatronda, 15</dd>
            <dd>31033 Castelfranco Veneto</dd>
            <dd>Italia</dd>
          </dl>
        </div>

        <div className="column second-column">
          <dl>
            <dt>Mail</dt>
            <dd>
              <a href={"mailto:contact@salesbridge.it"}>
                contact@salesbridge.it
              </a>
            </dd>
          </dl>

          <dl>
            <dt>Telefono</dt>
            <dd>
              <a href={"tel:+3904381896076"}>0438 1896076</a>
            </dd>
          </dl>
          <dl>
            <dt>Cellulare</dt>
            <dd>
              <a href={"tel:+393351816547"}>335 1816547</a>
            </dd>
          </dl>
          <dl>
            <dt>Partita IVA</dt>
            <dd>05013730261</dd>
          </dl>
          <dl>
            <dt>Cap. Soc.</dt>
            <dd>80.000 euro i.v.</dd>
          </dl>
        </div>

        <RoundedButton
          className="glooke-link-btn"
          type="grey"
          onClick={goToGlookeWebsite}
          width={326}
        >
          SCOPRI GLOOKE
        </RoundedButton>
      </div>

      <div className="bottom-bar">
        <Link to="/cookie/">
          <span>Cookie</span>
        </Link>
        <Link to="/privacy/">
          <span>Privacy</span>
        </Link>

        <button type="button" onClick={handleToggleCreditsModal}>
          <span>Crediti</span>
        </button>
      </div>

      <CreditsModal
        visible={isCreditsModalVisible}
        onClose={handleToggleCreditsModal}
      />
    </footer>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Footer.propTypes = {
  className: PropTypes.string.isRequired,
}

Footer.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledFooter = styled(Footer)`
  & {
    display: flex;
    flex-direction: column;
    padding: 31.5px 33px 12px 33px;
    box-sizing: border-box;
    width: 100vw;
    color: ${props => props.theme.primaryTextColor};
    box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.08);

    .info-container {
      flex: 1;
      display: flex;
      flex-direction: row;

      .logo {
        margin-right: 60.7px;
      }

      .column {
        display: flex;
        flex-direction: column;
      }

      .first-column {
        .company-name {
          margin-bottom: 23px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        dl {
          margin: 0;
          font-family: "Poppins ExtraLight";
          font-size: 16px;
          line-height: 22px;

          dd {
            margin: 0;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;
            color: ${props => props.theme.primaryTextColor};
          }
        }
      }

      .second-column {
        flex: 1;
        margin-left: 57px;
        padding-top: 45px;

        dl {
          margin: 0;
          font-family: "Poppins ExtraLight";
          font-size: 16px;
          line-height: 22px;

          dt {
            display: inline;
            font-family: "Poppins ExtraLight";
          }
          dd {
            display: inline;
            margin: 0 0 0 8px;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;

            a {
              text-decoration: none;
              color: ${props => props.theme.primaryTextColor};
              font-size: 16px;
              line-height: 22px;

              transition: all 0.2s ease;

              :hover {
                color: ${props => props.theme.pearlAqua};
              }
            }
          }
        }
      }

      .glooke-link-btn {
        float: right;
      }
    }

    .bottom-bar {
      margin-top: 45.1px;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 18.6px;
      flex-direction: row;
      justify-content: center;

      a {
        text-decoration: none;
        transition: all 0.2s ease;

        > span {
          font-size: 16px;
          line-height: 22px;
          color: ${props => props.theme.primaryTextColor};
          font-weight: 600;
          transition: all 0.2s ease;
        }

        :hover {
          > span {
            color: ${props => props.theme.pearlAqua};
          }
        }
      }

      button {
        padding: 0;
        border: none;
        background-color: transparent;
        font-family: "Poppins";
        font-size: 16px;
        line-height: 22px;
        color: ${props => props.theme.primaryTextColor};
        font-weight: 600;
        transition: all 0.2s ease;

        cursor: pointer;

        :hover {
          color: ${props => props.theme.pearlAqua};
        }

        :focus {
          outline: none;
        }
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      padding: 35.8px 24px 24px 20.6px;
      height: 493.05px;

      .info-container {
        flex-direction: column;

        .first-column {
          margin-top: 25.9px;
        }

        .second-column {
          padding-top: 22px;
          margin-left: 0px;
        }

        .glooke-link-btn {
          margin-top: 33.9px;
          width: 100%;
        }
      }

      .bottom-bar {
        margin-top: 40.7px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledFooter
