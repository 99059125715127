import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import SearchBar from "../../SearchBar"

import CreditsModal from "../CreditsModal"

import HamburgerMenu from "./HamburgerMenu"
import SearchTextInput from "./SearchTextInput"

// ----------------------------------------------------------------------------

const Header = ({ ...props }) => {
  const [isMenuOpen, toggleMenu] = useState(false)
  const [hasBeenToggled, setHasBeenToggled] = useState(false)
  const [isMobileUI, setIsMobileUI] = useState(null)
  const [isCreditsModalVisible, setIsCreditsModalVisible] = useState(false)

  // -------------------------------------

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleWindowResize()

      window.addEventListener("resize", handleWindowResize)
    }

    return function cleanup() {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  // -------------------------------------

  function handleWindowResize() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia && !matchMedia.matches) {
        setHasBeenToggled(false)
        toggleMenu(false)
        setIsMobileUI(false)
      } else {
        setIsMobileUI(true)
      }
    }
  }

  function handleToggleCreditsModal() {
    const newModalVisibility = !isCreditsModalVisible

    setIsCreditsModalVisible(newModalVisibility)
  }

  function closeMobileMenu() {
    toggleMenu(false)
  }

  function handleMenuClick() {
    !hasBeenToggled && setHasBeenToggled(true)

    toggleMenu(!isMenuOpen)
  }

  if (typeof window !== "undefined") {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }

  function goToSearchPage(text) {
    navigate(`/search?query=${text || ""}`)
  }

  // -------------------------------------
  return (
    <div className={props.className}>
      <header className={props.className}>
        <Link to="/">{!isMenuOpen && <div className="logo" />}</Link>

        <ul className="menu-container">
          <li>
            <Link to="/services/" activeClassName="current-route">
              <span>Servizi</span>
            </Link>
          </li>
          <li>
            <Link to="/company/" activeClassName="current-route">
              <span>Azienda</span>
            </Link>
          </li>
          <li>
            <Link to="/faq/" activeClassName="current-route">
              <span>FAQ</span>
            </Link>
          </li>
          <li>
            <Link to="/contacts/" activeClassName="current-route">
              <span>Contatti</span>
            </Link>
          </li>

          <li>
            <SearchTextInput onSearch={goToSearchPage} />
          </li>
        </ul>

        <HamburgerMenu
          className="mobile-menu-btn"
          isMenuOpen={isMenuOpen}
          onClick={handleMenuClick}
        />
      </header>

      {hasBeenToggled && isMobileUI && (
        <div
          className={`mobile-menu-dropdown ${isMenuOpen ? "open" : "close"}`}
        >
          <div>
            <img
              className="vertical-logo"
              src={require("../../../../images/logo/logo-payoff.svg")}
            />
            <div className="routes-link-container">
              <Link to="/" activeClassName="current-route">
                <span onClick={closeMobileMenu}>Home</span>
              </Link>
              <Link to="/services/" activeClassName="current-route">
                <span onClick={closeMobileMenu}>Servizi</span>
              </Link>
              <Link to="/company/" activeClassName="current-route">
                <span onClick={closeMobileMenu}>Azienda</span>
              </Link>

              <Link to="/faq/" activeClassName="current-route">
                <span onClick={closeMobileMenu}>FAQ</span>
              </Link>
              <Link to="/contacts/" activeClassName="current-route">
                <span onClick={closeMobileMenu}>Contatti</span>
              </Link>
            </div>

            <div className="search-bar">
              <SearchBar
                placeholder="Qualche dubbio?"
                onSearch={goToSearchPage}
              />
            </div>
            <div className="footer">
              <Link to="/cookie/">
                <span>Cookie</span>
              </Link>
              <Link to="/privacy/">
                <span>Privacy</span>
              </Link>

              <button type="button" onClick={handleToggleCreditsModal}>
                <span>Crediti</span>
              </button>

              <CreditsModal
                visible={isCreditsModalVisible}
                onClose={handleToggleCreditsModal}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

// ----------------------------------------------------------------------------

Header.propTypes = {
  siteTitle: PropTypes.string,
  className: PropTypes.string,
  mobileMenuVisibilityDidChange: PropTypes.func,
  theme: PropTypes.object,
  pageTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

// ----------------------------------------------------------------------------

const HeaderWithTheme = withTheme(Header)

// ----------------------------------------------------------------------------

const StyledHeader = styled(HeaderWithTheme)`
  & {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    > header {
      box-sizing: border-box;
      padding: 0 20px;
      height: 69.65px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: ${props => props.theme.white};
      color: #ffffff;
      box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.13);

      .logo {
        min-width: 271.31px;
        max-width: 271.31px;
        min-height: 32.3px;
        background-image: ${`url(${require("../../../../images/logo/logo.svg")})`};
        background-size: contain;
        background-repeat: no-repeat;
      }

      .menu-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        > li {
          margin-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;

          cursor: pointer;

          a {
            text-decoration: none;
            font-size: 18px;
            line-height: 24px;
            color: ${props => props.theme.primaryTextColor};
            transition: all 0.2s ease;

            :hover {
              color: ${props => props.theme.pearlAqua};
            }
          }

          a.current-route {
            position: relative;

            :hover {
              color: ${props => props.theme.primaryTextColor};
            }

            ::after {
              content: "";
              position: absolute;
              top: 29.5px;
              left: 50%;
              min-width: 16px;
              max-width: 16px;
              min-height: 4px;
              max-height: 4px;
              background-color: ${props => props.theme.yaleBlue};
              transform: translateX(-50%);
            }
          }
        }
      }

      .mobile-item {
        display: none;
      }

      .mobile-menu-btn {
        display: none;
      }
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      > header {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0 10px 0 10px;
        max-height: 50px;
        overflow: hidden;

        .logo {
          min-height: 22.21px;
          max-height: 22.21px;
          background-image: ${`url(${require("../../../../images/logo/logo.svg")})`};
        }

        .menu-container {
          display: none;
        }

        .mobile-item {
          display: inline-block;
        }

        .page-title {
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
          color: ${props => props.theme.yaleBlue};
          text-transform: uppercase;
        }

        .mobile-menu-btn {
          float: right;
          display: inline-block;
        }
      }

      .mobile-menu-dropdown {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        top: 20vh;
        left: 0;
        width: 100%;
        min-height: calc(100vh - 50px);
        height: calc(100vh - 50px);
        max-height: calc(100vh - 50px);

        box-sizing: border-box;

        background-color: #ffffff;
        opacity: 0; /* start value */
        z-index: -1; /* start value */
        overflow-y: scroll;

        > div {
          position: relative;
          height: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .vertical-logo {
          max-width: 90vw;
        }

        .routes-link-container {
          flex: 1;
          margin-top: 10vh;
          display: flex;
          flex-direction: column;

          > a {
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: 100%;

            color: ${props => props.theme.primaryTextColor};
            text-decoration: none;
            text-align: right;
            font-size: 18px;
            line-height: 24px;
          }
        }

        .search-bar {
          min-width: 100vw;
        }

        .footer {
          height: 64px;
          width: 100vw;
          background-color: ${props => props.theme.yaleBlue};

          display: grid;
          grid-template-columns: auto auto auto;
          align-items: center;

          grid-column-gap: 18.6px;
          flex-direction: row;
          justify-content: center;

          a {
            text-decoration: none;
            transition: all 0.2s ease;

            > span {
              font-size: 16px;
              line-height: 22px;
              color: ${props => props.theme.white};
              font-weight: 600;
              transition: all 0.2s ease;
            }

            :hover {
              > span {
                color: ${props => props.theme.pearlAqua};
              }
            }
          }

          button {
            padding: 0;
            border: none;
            background-color: transparent;
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;
            color: ${props => props.theme.white};
            font-weight: 600;
            transition: all 0.2s ease;

            cursor: pointer;

            :hover {
              color: ${props => props.theme.pearlAqua};
            }

            :focus {
              outline: none;
            }
          }
        }
      }

      .mobile-menu-dropdown.open {
        animation-name: menuSlideIn;
        animation-duration: 0.15s;
        animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
        animation-fill-mode: forwards;
      }

      .mobile-menu-dropdown.close {
        animation-name: menuSlideOut;
        animation-duration: 0.15s;
        animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);
        animation-fill-mode: forwards;
      }
    }

    @keyframes menuSlideIn {
      0% {
        z-index: -1;
        opacity: 0;
        top: 20vh;
      }
      100% {
        z-index: 999;
        opacity: 1;
        top: 50px;
      }
    }

    @keyframes menuSlideOut {
      0% {
        z-index: 999;
        opacity: 1;
        top: 50px;
      }
      100% {
        z-index: -1;
        opacity: 0;
        top: 20vh;
      }
    }
  }
`

export default StyledHeader
