import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Divider from "../../Divider"

//------------------------------------------------------------------------------

function CreditsModal(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { visible, onClose } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function goToNotoriousWebsite() {
    if (typeof window !== "undefined") {
      window.open("https://notorious.agency/", "https://notorious.agency/")
    }
  }

  function goToDiscontinuityWebsite() {
    if (typeof window !== "undefined") {
      window.open(
        "http://www.discontinuity.eu/",
        "http://www.discontinuity.eu/"
      )
    }
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  // SSR compiler check
  if (typeof window !== "undefined") {
    if (visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }

  return (
    <div className={`${props.className}${visible ? " visible" : ""}`}>
      <div className="mask" />
      <div className="modal-card">
        <div className="close-icn" onClick={onClose} />
        <div className="modal-content">
          <span className="title">Un sito strepitoso di</span>

          <Divider />

          <div className="credit-company" onClick={goToNotoriousWebsite}>
            <img src={require("../../../../images/logo/notorious-logo.svg")} />
            <h3>
              Notorious
              <br />
              Agency
            </h3>

            <p>
              Concepting
              <br />
              UX/UI Design <br />
              Contents
              <br />
            </p>
          </div>
          <Divider />

          <div className="credit-company" onClick={goToDiscontinuityWebsite}>
            <img
              src={require("../../../../images/logo/discontinuity-logo.svg")}
            />
            <h3>Discontinuity</h3>
            <p>Developement</p>
          </div>
          <Divider />
        </div>
      </div>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CreditsModal.propTypes = {
  className: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

CreditsModal.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCreditsModal = styled(CreditsModal)`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    transform: scale(0);
    transform-origin: bottom center;
    transition: transform 0.2s ease-in-out;

    z-index: 998;

    .mask {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 999;
      opacity: 0;
      transition: opacity 0.1s ease 0.2s;
    }

    .modal-card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 634px;
      height: 492px;
      max-width: 634px;
      max-height: 492px;

      border-radius: 6px;
      background-color: ${props => props.theme.white};
      box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
      z-index: 1000;

      .close-icn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 18px;
        width: 16px;
        height: 16px;
        background-image: url(${require("../../../../images/_shared/icons/close-icn.svg")});
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-origin: content-box;

        cursor: pointer;

        transition: all 0.2s ease;

        :hover {
          background-image: url(${require("../../../../images/_shared/icons/close-pearl-aqua-icn.svg")});
        }
      }

      .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 309.5px;
        max-width: 309.5px;

        color: ${props => props.theme.primaryTextColor};
        font-size: 18px;
        line-height: 24px;

        .title {
          margin-bottom: 14.6px;
        }

        .credit-company {
          margin-top: 35px;
          margin-bottom: 17.8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          h3 {
            font-size: 21px;
            line-height: 24px;
            font-weight: 600;
            color: ${props => props.theme.primaryTextColor};
            text-align: center;
          }

          p {
            margin-top: 2px;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
  }

  &.visible {
    transform: scale(1);

    .mask {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      .modal-card {
        width: 90vw;

        .modal-content {
          padding: 0 26px;
          box-sizing: border-box;
          max-width: 100%;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledCreditsModal
