import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function FloatingButton(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onClick, icon } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <button className={props.className} onClick={onClick}>
      <div className="btn-icn" />
    </button>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

FloatingButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.any,
}

FloatingButton.defaultProps = {
  onClick: () => {},
  icon: null,
}

// ----------------------------------------------------------------------------

const StyledFloatingButton = styled(FloatingButton)`
  & {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50%;

    background-color: ${props =>
      (props.backgroundColor && props.backgroundColor) ||
      props.theme.isabelline};

    transition: all 0.2s ease;

    cursor: pointer;

    .btn-icn {
      height: 40px;
      width: 40px;
      background-image: url(${props => props.icon && props.icon});
      background-size: auto auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-origin: content-box;

      transition: all 0.2s ease;

      :hover {
        background-image: url(${props =>
          (props.hoverIcon && props.hoverIcon) || props.icon});
      }
    }

    :hover {
      background-color: ${props =>
        (props.hoverBackgroundColor && props.hoverBackgroundColor) ||
        props.backgroundColor};

      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    :focus {
      outline: none;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledFloatingButton
