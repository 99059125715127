import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

//------------------------------------------------------------------------------

function TextInput(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, placeholder, ...inputProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [fileName, setFileName] = useState(null)
  const [isInputFocused, setInputFocused] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFileUploaded(event) {
    const fileName =
      event.target &&
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0].name

    setFileName(fileName)
  }

  function handleInputFocus() {
    setInputFocused(true)
  }

  function handleInputBlur() {
    setInputFocused(false)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={className}>
      <button className={`upload-btn${isInputFocused ? " focused" : ""}`}>
        <span className={`${fileName ? "value" : "placeholder"}`}>
          {fileName || placeholder}
        </span>
        <img src={require("./up-arrow-icn.svg")} />
      </button>
      <input
        className="file-input"
        type="file"
        onChange={handleFileUploaded}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...inputProps}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TextInput.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

TextInput.defaultProps = {
  placeholder: "Carica un file!",
}

// ----------------------------------------------------------------------------

const StyledTextInput = styled(TextInput)`
  & {
    position: relative;
    cursor: pointer;

    .upload-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;

      box-sizing: border-box;
      padding: 16px 19.9px 16px 20px;
      background-color: ${props => props.theme.white};
      border-style: none;
      border-radius: 2px;
      height: 50px;
      width: 100%;
      text-align: left;
      cursor: pointer;

      > span {
        font-size: 16px;
        color: ${props => props.theme.primaryTextColor};
      }

      > span.placeholder {
        font-size: 16px;
        color: ${props => props.theme.primaryTextColor};
        font-weight: normal;
      }

      > span.value {
        color: ${props => props.theme.primaryTextColor};
        font-weight: 600;
      }
    }

    .focused {
      outline: none;
      background-color: ${props => props.theme.jonquil};
      font-weight: 600;
    }

    input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      max-height: 50px;
      max-width: 100%;
      cursor: pointer;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledTextInput
