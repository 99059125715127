import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import FloatingButton from "../FloatingButton"

//------------------------------------------------------------------------------

function SearchBar(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onSearch, placeholder } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [inputValue, setInputValue] = useState(null)
  const [isInputOnFocus, setIsInputOnFocus] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleInputFocusing() {
    setIsInputOnFocus(true)
  }

  function handleInputBlur() {
    if (!inputValue) {
      setIsInputOnFocus(false)
    }
  }

  function handleOnSearch() {
    onSearch && onSearch(inputValue)
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className}>
      <img src={require("../../../images/_shared/icons/search-icn.svg")} />

      <input
        className="search-input"
        placeholder={placeholder}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        onFocus={handleInputFocusing}
        onBlur={handleInputBlur}
      />

      <FloatingButton
        className={`search-btn${isInputOnFocus ? " focused" : ""}`}
        icon={require("../../../images/_shared/icons/right-arrow-icn.svg")}
        backgroundColor={"#FFFFFF"}
        onClick={handleOnSearch}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

SearchBar.propTypes = {
  className: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
  onSearch: () => {},
  placeholder: "Qualche dubbio? Cerca tra le FAQ!",
}

// ----------------------------------------------------------------------------

const StyledSearchBar = styled(SearchBar)`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;

    box-sizing: border-box;
    padding: 0 33px;
    height: 64px;
    width: 100%;
    max-width: 100vw;
    background-color: ${props => props.theme.pearl};
    overflow: hidden;

    .search-input {
      flex: 1;
      max-width: 350px;
      width: auto;
      margin-left: 24px;

      border: none;
      background-color: transparent;

      font-size: 18px;
      line-height: 24px;
      color: ${props => props.theme.primaryTextColor};

      :focus {
        outline: none;
      }
    }

    .search-btn {
      opacity: 0;
      transform: translateX(100px);
      transition: all 0.3s ease-in-out;
    }

    .search-btn.focused {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledSearchBar
