/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./Header"
import Footer from "./Footer"

// ----------------------------------------------------------------------------

import "./index.css"
import "./fonts.css"

// ----------------------------------------------------------------------------

const Layout = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // -------------------------------------

  const { pageTitle, layoutMode } = props

  // -------------------------------------

  return (
    <div className={props.className}>
      <Header siteTitle={data.site.siteMetadata.title} pageTitle={pageTitle} />
      <main>{children}</main>

      {layoutMode !== "maintenance" && <Footer />}
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  layoutMode: PropTypes.string,
}

const StyledLayout = styled(Layout)`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    min-width: 100vw;
    max-width: 100vw;
    overflow: hidden;

    * {
      font-family: "Poppins";
    }

    > main {
      flex: 1;
    }

    /* MOBILE */

    @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
      > main {
        padding-top: 50px;
      }
    }
  }
`

export default StyledLayout
