import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function Divider(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return <div className={props.className} />
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Divider.propTypes = {
  className: PropTypes.string.isRequired,
}

Divider.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledDivider = styled(Divider)`
  & {
    width: 100%;
    min-height: 1px;
    height: 1px;
    max-height: 1px;
    background-color: ${props => props.theme.ashGrey};
  }
`
// ----------------------------------------------------------------------------

export default StyledDivider
