import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

//------------------------------------------------------------------------------

function RoundedButton(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children, type, onClick } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <button className={`${props.className} ${type}`} onClick={onClick}>
      {children}
    </button>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

RoundedButton.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  width: PropTypes.any,
}

RoundedButton.defaultProps = {
  type: "yellow",
  children: "Click me!",
  onClick: () => {},
  width: null,
}

// ----------------------------------------------------------------------------

const StyledRoundedButton = styled(RoundedButton)`
  & {
    border: 1px solid transparent;
    border-radius: 25px;

    width: ${props => {
      if (props.width && props.width) {
        if (typeof props.width === "string") {
          return props.width
        }
        return `${props.width}px`
      } else {
        return "148px"
      }
    }};

    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    cursor: pointer;

    transition: all 0.1s ease-in-out;

    :hover {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      background-color: ${props => props.theme.pearlAqua} !important;
      color: ${props => props.theme.yaleBlue} !important;
    }

    :active {
      box-shadow: none;
    }

    :focus {
      outline: none;
    }
  }

  &.yellow {
    height: 48px;
    background-color: ${props => props.theme.jonquil};
    color: ${props => props.theme.yaleBlue};
  }

  &.blue {
    height: 48px;
    background-color: ${props => props.theme.yaleBlue};
    color: ${props => props.theme.white};
  }

  &.grey {
    height: 47px;
    background-color: ${props => props.theme.isabelline};
    color: ${props => props.theme.blueTextColor};
    font-weight: bold;
  }
`
// ----------------------------------------------------------------------------

export default StyledRoundedButton
