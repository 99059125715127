import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

//------------------------------------------------------------------------------

function HamburgerMenu(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { onClick } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={props.className} onClick={onClick}>
      <div className="line" />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

HamburgerMenu.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

HamburgerMenu.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledHamburgerMenu = styled(HamburgerMenu)`
  & {
    width: 19px;
    min-height: 15px;
    max-height: 19px;

    ::before {
      margin-bottom: 4px;
    }

    ::after {
      margin-top: 4px;
    }

    ::before,
    ::after,
    .line {
      display: block;
      content: "";
      height: 2px;
      background-color: ${props => props.theme.ashGrey};
      transition: all 0.2s ease-in-out;
    }

    ${props => {
      const { isMenuOpen } = props

      if (isMenuOpen) {
        return css`
          ::before {
            transform: translateY(6px) rotate(135deg);
          }

          ::after {
            transform: translateY(-6px) rotate(-135deg);
          }

          .line {
            transform: scale(0);
          }
        `
      }
    }}
  }
`
// ----------------------------------------------------------------------------

export default StyledHamburgerMenu
