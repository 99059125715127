import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Fade from "react-reveal/Fade"

//------------------------------------------------------------------------------

function RevealOnScroll(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { children, ...animationProps } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <Fade duration={500} bottom {...animationProps}>
      {children}
    </Fade>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

RevealOnScroll.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
}

RevealOnScroll.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledRevealOnScroll = styled(RevealOnScroll)`
  & {
  }
`
// ----------------------------------------------------------------------------

export default StyledRevealOnScroll
